import { useContext } from "react";
import { TranslationsContext } from "globant";

const useTranslateText = (value) => {
  const translations =  useContext(TranslationsContext); // eslint-disable-line
  let text = value

  if (
    typeof translations !== 'undefined' &&
    translations.hasOwnProperty(value)
  ) {
    text = translations[value]
  }
  
  return text
}

export default useTranslateText;