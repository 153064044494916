import React, { useContext } from "react";
import { TranslationsContext } from "globant";

const GTranslate = (props) => {
  const translations =  useContext(TranslationsContext); // eslint-disable-line
  let text = props.children

  if (translations.hasOwnProperty(props.children)) {
    text = translations[props.children]
  }

  return (
    <>{text}</>
  )
}
export default GTranslate;