import { useContext } from "react";
import {LanguageContext} from "globant";

const useTranslateField = (props = {
  field: {},
  default: "",
  attr: ""
}) => {
  const [language, setLanguage] = useContext(LanguageContext); // eslint-disable-line

  let value = ""

  if (typeof props.default !== 'undefined') {
    value = props.default
  }

  if (typeof props.field === 'undefined') {
    return value
  }

  var valueTypes = ['value', 'url'];

  if (typeof props.attr !== 'undefined' && props.attr !== "") {
    valueTypes = [props.attr]
  }

  function translateCheck(field, language_code) {

    if (field.hasOwnProperty(language_code)) {
      if (
        field[language_code].length === 1
      ) {
        for (const valueType in valueTypes) {
          let type = valueTypes[valueType];

          if (field[language_code][0].hasOwnProperty(type)) {
            return field[language_code][0][type]
          }
        }
      }
      else {
        return field[language_code]
      }
    }
    else {
      if (field.hasOwnProperty('en')) {
        return translateCheck(field, 'en')
      }
      else {
        return ''
      }
    }
  }

  value = translateCheck(props.field, language);

  return value
}
export default useTranslateField;