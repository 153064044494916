import { useContext } from "react";
import { LanguageContext } from "globant";

const useHrefFormat = (props) => {
  const [language, setLanguage] = useContext(LanguageContext); // eslint-disable-line

  const regex = new RegExp(/^internal:/)
  let href = '#'

  if (props.uri !== "" && regex.test(props.uri)) {
    href = props.uri.replace('internal:', '')
  } else if (props.uri !== "" && !regex.test(props.uri)) {
    href = props.uri
  }

  let currentQueryArgs = ''

  if (typeof window?.location.search !== 'undefined') {
    currentQueryArgs = window?.location.search
  }

  if (href[0] === '#') {
    href = currentQueryArgs + href
  }

  const regexHashtag = new RegExp(/^#/)

  if (regexHashtag.test(href)) {
    href = '/' + href

    if (language !== 'es') {
      href = '/' + language + href
    }
  }

  return href
}

export default useHrefFormat;