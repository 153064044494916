import React, { useState, createContext } from "react"

export const TranslationsContext = createContext({});

const TranslationsProvider = (props) => {
  const [translations, setTranslations] = useState(props.translations); // eslint-disable-line

  return React.createElement(TranslationsContext.Provider, {
    value: props.translations
  }, props.children);
}

export default TranslationsContext
export { TranslationsProvider }