import { useContext } from "react";
import { LanguageContext } from "globant";

const useObjTranslate = (obj) => {
  const [language, setLanguage] = useContext(LanguageContext); // eslint-disable-line
  let text = ''

  if (obj.hasOwnProperty(language)) {
    text = obj[language]
  }

  return text
}
export default useObjTranslate;